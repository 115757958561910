import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts/courseLandingLayout"
import styled from "styled-components"
import Button from "../components/buttons/linkButton"
import SpotifyButton from "../components/buttons/spotifyButton"
import { Container, Col, Row } from "react-bootstrap"
import tatiana from "../assets/images/tatiana.jpg"
import Navbar from "../components/navbars/courseLandingNavbar"
import ReactGA from "react-ga"
import Bullet from "../assets/icons/bullet.png"
import Zulmy from "../assets/images/zulmy.jpg"
import Podcast from "../assets/images/podcast-episode.jpeg"
import Brian from "../assets/images/brian.jpg"
import IBM from "../assets/images/ibm-2.png"
import CreditSuisse from "../assets/images/creditsuisse.png"
import Chart from "../components/comparison"
import curriculum from "../assets/images/curriculum.png"
import ApplyBox from "../components/applyBox"
import FocusLeadGen from "../components/focusCourseExitIntent"
import SearchEngine from "../components/SearchEngine"
import SyllabusForm from "../components/forms/syllabusForm"
import PSModal from "../components/modal"
import useExitIntent from "../utils/exit-intent-hook"
import ExitIntentModal from "../components/exit-modal"

import Helmet from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpotify } from "@fortawesome/free-brands-svg-icons"

const TwoToneBackground = styled.div`
  // background: linear-gradient(to left, #ffffff 70%, #ed5942 30%);
  padding-bottom: 80px;

  @media screen and (max-width: 767px) {
    background: none;
    background-size: cover;
    background-position: center center;
  }

  .cta-learn-more {
    margin-left: 20px;
  }
`

const HeroContainer = styled(Container)`
  max-width: 1000px;
  padding-top: 140px;
  font-family: sans-serif;

  .cta-syllabus {
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .col-hero-image {
    img {
      box-shadow: -20px 20px var(--black);
      border: 5px solid var(--black);
    }
    position: static;
  }

  .col-hero-text {
    padding-top: 100px;
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
`

const AboutSection = styled.section`
  background-color: #ecf1f4;
  padding-top: 80px;

  h2 {
    margin-top: 80px;
    text-align: center;
  }

  .compare-text {
    padding-top: 20px;
    padding-right: 40px;
  }

  .comparison-chart {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .comp-logo-img {
    max-width: 130px;
  }

  .btn-curriculum {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
`

const ListenSection = styled.section`
  background-color: #fff;
  padding-top: 80px;

  h2 {
    margin-top: 80px;
    text-align: center;
  }

  .compare-text {
    padding-top: 20px;
    padding-right: 40px;
  }

  .comparison-chart {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .comp-logo-img {
    max-width: 130px;
  }

  .btn-curriculum {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
`

const AboutContainer = styled(Container)`
  .about-left {
    p {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 0px;
    }

    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }

  .about-right {
    padding: 0;
    max-width: 1000px;

    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }
`

const ListenContainer = styled(Container)`
  .listen-left {
    padding: 0;
    max-width: 1000px;

    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }

  .listen-right {
    padding-left: 60px;

    p {
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 0px;
    }

    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }
`

const Index = ({ location }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  const { showPopup, setShowPopup } = useExitIntent()

  console.log("show pop up", showPopup)

  return (
    <Layout>
      <SearchEngine title="Online Coding Bootcamp" />
      <TwoToneBackground>
        <Navbar location={location} />
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <ExitIntentModal show={showPopup} setShowPopup={setShowPopup}>
          <FocusLeadGen />
        </ExitIntentModal>

        <HeroContainer>
          <Row>
            <Col md={5}>
              <div style={{ width: "100%", height: "100%" }}>
                <iframe
                  style={{
                    borderRadius: "12px",
                    border: "none",
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://open.spotify.com/embed/episode/6C2jwBXfS8TqjJX0MajJJb?utm_source=generator&theme=0"
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
            </Col>
            <Col className="col-hero-text" md={7}>
              <h1>DEVELOP YOURSELF_</h1>
              <p className="subtext">
                The Software Development podcast to help you develop yourself -
                your habits, your focus, your network and more.
              </p>

              <a
                target="_blank"
                href="https://open.spotify.com/show/69BHCbRAl6rHT9LlNhFWUy?si=6d4573a665a9467e"
              >
                <SpotifyButton
                  link={true}
                  fireGTMEvent={fireGTMEvent}
                  text={"Listen on Spotify"}
                />
              </a>
            </Col>
          </Row>
        </HeroContainer>
      </TwoToneBackground>
      <AboutSection>
        <Container>
          <Row className="about-row">
            <AboutContainer fluid>
              <Row>
                <Col className="about-left" md={7}>
                  <p className="compare-text">
                    Hey, I'm Brian, Owner of Parsity and host of the Develop
                    Yourself Podcast. My life's passion is to help people get
                    "unstuck", and I believe that learning to code and changing
                    careers is a power tool to do that.
                    <br></br>
                    <br></br>
                    I've become convinced that "personal development" is the
                    most important factor in whether or not you'll be successful
                    in this career change.
                    <br></br>
                    <br></br>
                    <p>
                      On this show, we'll cover strategies for developing
                      yourself that will ensure you're successful if and when
                      you decide to take and plunge and become a Software
                      Engineer.
                    </p>
                    <a
                      target="_blank"
                      href="https://open.spotify.com/show/69BHCbRAl6rHT9LlNhFWUy?si=6d4573a665a9467e"
                    >
                      <SpotifyButton
                        link={true}
                        fireGTMEvent={fireGTMEvent}
                        text={"Listen on Spotify"}
                      />
                    </a>
                  </p>
                </Col>
                <Col className="about-right" md={5}>
                  <div className="cover">
                    <img src={Brian} alt=""></img>
                  </div>
                </Col>
              </Row>
            </AboutContainer>
          </Row>
        </Container>
      </AboutSection>
    </Layout>
  )
}

export default Index
